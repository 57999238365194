/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import { getWagonClass } from '../../../common/Utils';
import { nanoid } from 'nanoid';
import changePage from './SeatSlideChange';
import { calculateHeight, commonStyle, getBackground, getBorder, getPassengerIndex, handleSeatClick, initSeatLayout, seatColumnStyle, seatRowIndexStyle, useStyle } from './seatLayoutUtils';

const TwoByTwo = ({ selectedClass, setSelectedClass, setSeatChange, currentSelectedClassLayout }) => {

  const [classSeatArray, setClassSeatArray] = useState([]);
  const [passengerList, setPassengerList] = useState([]);
  const [currentPassengerIndex, setCurrentPassengerIndex] = useState('');

  const [noOfRows, setNoOfRows] = useState({});
  const [height, setHeight] = useState('100px');

  const classes = useStyle();

  useEffect(() => {
    const layoutProps = { getWagonClass, setCurrentPassengerIndex, setPassengerList, setClassSeatArray }
    initSeatLayout(layoutProps)
  }, []);

  useEffect(() => {
    if (currentSelectedClassLayout) {
      const heightProps = {setHeight, currentSelectedClassLayout, setNoOfRows}
      calculateHeight(heightProps)
    }
  }, [currentSelectedClassLayout]);

  return (

    <Carousel autoPlay={false}
      swipe={false}
      navButtonsAlwaysVisible={true}
      indicatorContainerProps={{
        style: { marginTop: height, visibility: "hidden" }
      }}
      animation={"slide"}
      navButtonsProps={{ className: classes.carouselNavButtons }}

      next={(next, active) => { if (active || next) changePage('next', selectedClass, setSelectedClass) }}
      prev={(prev, active) => { if (active || prev) changePage('prev', selectedClass, setSelectedClass) }}
    >
      {
        classSeatArray.map((v, i) =>
          <div key={`row-seat-two-by-two-container-${nanoid()}`} >
            <RowSeat
              selectedClass={selectedClass}
              passengerList={passengerList} setPassengerList={setPassengerList}
              currentPassengerIndex={currentPassengerIndex}
              setSeatChange={setSeatChange}
              noOfRows={noOfRows}
            />
          </div>
        )
      }
    </Carousel>

  )
}

const RowIndex = () => {
  return (
    <Grid container justifyContent={"center"} id="grid">
      <Grid item style={seatColumnStyle}>A</Grid>
      <Grid item style={seatColumnStyle}>B</Grid>
      <Grid item style={{width:"32px", height:"32px",marginRight:'10px'}}>{" "}</Grid>
      <Grid item style={seatColumnStyle}>C</Grid>
      <Grid item style={seatColumnStyle}>D</Grid>
    </Grid>
  )
}

const RowSeat = ({ selectedClass, passengerList, setPassengerList, currentPassengerIndex, setSeatChange, noOfRows }) => {

  return (
    <div>
      <RowIndex />
      {
        noOfRows?.rows?.map((data, i) => {
          const classSeatAvailableList = noOfRows.available;
          const classSeatNotAvailableList = noOfRows.notAvailable;
          const length = noOfRows.rows.length * 4;

          const SEAT_ROW = (data + 1);

          {/* used in styles for setting visibility */ }
          const isVisibleA = ((i * 4) + 1 <= length);
          const isVisibleB = ((i * 4) + 2 <= length);
          const isVisibleC = ((i * 4) + 3 <= length);
          const isVisibleD = ((i * 4) + 4 <= length);

          const seatProps = {
            passengerIndex: currentPassengerIndex,
            passengerList, 
            selectedClass, 
            setPassengerList, 
            setSeatChange,  
            classSeatAvailableList
          }

          return (
            <div id={data} key={data} >
              <Grid container justifyContent={"center"} style={{ marginBottom: "24px" }}>

                {/* --- A --- */}
                <Grid id={(SEAT_ROW + 'A')} item className={'seatBox'}
                  style={{
                    fontFamily: `'DM Sans', sans-serif`,
                    visibility: isVisibleA ? '' : 'hidden',
                    background: getBackground(passengerList, classSeatAvailableList, classSeatNotAvailableList, (SEAT_ROW + 'A'), (selectedClass + "," + SEAT_ROW + 'A')),
                    border: getBorder(passengerList, (selectedClass + ',' + SEAT_ROW + 'A'))
                  }}
                  onClick={() => {
                    seatProps.seat = SEAT_ROW + 'A'
                    handleSeatClick(seatProps)
                  }}
                >
                  {getPassengerIndex(passengerList, (selectedClass + "," + SEAT_ROW + 'A'))}
                </Grid>

                {/* --- B --- */}
                <Grid id={(SEAT_ROW + 'B')} item className={'seatBox'}
                  style={{
                    fontFamily: `'DM Sans', sans-serif`,
                    visibility: isVisibleB ? '' : 'hidden',
                    background: getBackground(passengerList, classSeatAvailableList, classSeatNotAvailableList, (SEAT_ROW + 'B'), (selectedClass + "," + SEAT_ROW + 'B')),
                    border: getBorder(passengerList, (selectedClass + ',' + SEAT_ROW + 'B'))
                  }}
                  onClick={() => {
                    seatProps.seat = SEAT_ROW + 'B'
                    handleSeatClick(seatProps)
                  }}
                >
                  {getPassengerIndex(passengerList, (selectedClass + "," + SEAT_ROW + 'B'))}
                </Grid>

                {/* --- ROW GAP --- */}
                {/* <Grid item style={{ height: '32px', width: '22px' }}>{" "}</Grid> */}

                 {/* --- INDEX --- */}
                 <Grid item style={ seatRowIndexStyle }>{data + 1}</Grid>

                {/* --- C --- */}
                <Grid id={(SEAT_ROW + 'C')} item className={'seatBox'}
                  style={{
                    fontFamily: `'DM Sans', sans-serif`,
                    visibility: isVisibleC ? '' : 'hidden',
                    background: getBackground(passengerList, classSeatAvailableList, classSeatNotAvailableList, (SEAT_ROW + 'C'), (selectedClass + "," + SEAT_ROW + 'C')),
                    border: getBorder(passengerList, (selectedClass + ',' + SEAT_ROW + 'C'))
                  }}
                  onClick={() => {
                    seatProps.seat = SEAT_ROW + 'C'
                    handleSeatClick(seatProps)
                  }}
                >
                  {getPassengerIndex(passengerList, (selectedClass + "," + SEAT_ROW + 'C'))}
                </Grid>

                {/* --- D --- */}
                <Grid id={(SEAT_ROW + 'D')} item className={'seatBox'}
                  style={{
                    fontFamily: `'DM Sans', sans-serif`,
                    visibility: isVisibleD ? '' : 'hidden',
                    background: getBackground(passengerList, classSeatAvailableList, classSeatNotAvailableList, (SEAT_ROW + 'D'), (selectedClass + "," + SEAT_ROW + 'D')),
                    border: getBorder(passengerList, (selectedClass + ',' + SEAT_ROW + 'D'))
                  }}
                  onClick={() => {
                    seatProps.seat = SEAT_ROW + 'D'
                    handleSeatClick(seatProps)
                  }}
                >
                  {getPassengerIndex(passengerList, (selectedClass + "," + SEAT_ROW + 'D'))}
                </Grid>

              </Grid>
            </div>
          )
        })

      }
    </div>
  )

}

export default TwoByTwo;
